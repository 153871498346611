.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: auto;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  
  .help-and-support {
    width: 80%;
    max-width: 800px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .header {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .card {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .section-header .icon {
    margin-right: 10px;
  }
  
  .section-header-text {
    font-size: 20px;
    font-weight: bold;
    color: #2bb673;
  }
  
  .faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 5px;
  }
  
  .faq-question-text {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  
  .faq-answer {
    font-size: 14px;
    color: #555555;
    margin-top: 5px;
    margin-left: 10px;
    padding: 5px;
    display: none;
  }
  
  .faq-answer.open {
    display: block;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .contact-text {
    font-size: 16px;
    color: #555555;
    margin-left: 10px;
  }
  
  .support-text {
    font-size: 14px;
    color: #555555;
    margin-top: 10px;
  }
  