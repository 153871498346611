/* General Styles */
.homepage {
}

.homepage h1 {
  font-size: 50px;
  font-weight: bold;
}

.homepage p {
  margin-top: 10px;
  font-size: 50px;
}

.lcontent {
  display: flex;
  height: 40vh;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 10%;
}

.lcontent h1 {
  font-size: 35px;
  margin: 0px;
}

.lcontent p {
  font-size: 35px;
  margin: 0px;
}

.button-container {
  display: flex;
  width: 100%;
  height: 40vh;
}

.button {
  flex: 1;
  height: 100%;
  font-size: 64px;
  cursor: pointer;
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D9D9D9;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  color: black;
}

.button p {
  font-size: 4.5vw;
  margin: 0px;
  color: black;
}

.button:first-child {
  margin-right: 10px;
}

.button:hover {
  color: white;
  background-color: #2BB673;
}
.button:hover p{
  color: white;
}

.button.active {
  background-color: #2BB673; /* Change background color when active */
  color: white;
}

.button-1 {
  font-weight: bold;
  clip-path: polygon(0 0, calc(100% - 5px) 0, calc(100% - 55px) 100%, 0% 100%);
}

.button-2 {
  font-weight: bold;
  clip-path: polygon(45px 0, 100% 0, 100% 100%, 0 100%);
}

.expanded {
  flex-grow: 2.4;
  color: white;
}

.contracted {
  flex-grow: 1;
  color: white;
}

@media (max-width: 768px) {
  .button-container {
    height: auto;
  }

  .button {
    width: 50%;
    height: 20vh;
    font-size: 24px;
    padding: 10px;
    margin: 0;
    clip-path: none;
  }

  .button p {
    font-size: 6vw;
  }

  .lcontent h1 {
    font-size: 25px;
    margin-left: 10px;
  }

  .lcontent p {
    font-size: 25px;
    margin-left: 10px;
  }

  .button.active {
    background-color: #2BB673;
    color: white;
  }
}

@media (max-width: 480px) {
  .button-container {
    height: auto;
  }

  .button p {
    font-size: 8vw;
  }
}
