/* Overlay styles */
.otp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* OTP box styles */
.otp {
  background: white;
  border-radius: 20px;
  height: 40vh;
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

/* Header styles */
.otp h3 {
  margin-bottom: 20px;
  font-size: x-large;
  font-weight: 500;

  color: black;
}

/* OTP input styles */
.otp-inputs {
  display: flex;
  justify-content: center;
}

.otp-inputs input {
  width: 50px;
  height: 50px;
  margin: 0 5px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #ccc;
  outline: none;
  border-radius: 5px;
  transition: border-color 0.3s;
  -moz-appearance: textfield;
}

.otp-inputs input::-webkit-outer-spin-button,
.otp-inputs input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-inputs input:focus {
  border: 2px solid #2bb673;
}

/* Verify button styles */
.verify-button {
  padding: 10px 20px;
  background: #2bb673;
  color: white;
  border: none;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 10px;
  height: 18%;
  width: 40%;
  font-size: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify-button:hover {
  background: #45a049;
}

@media (max-width: 1028px) {
  .otp-inputs input {
    width: 40px;
    height: 40px;
    margin: 0 5px;
    font-size: 20px;
    text-align: center;
    border: 2px solid #ccc;
    outline: none;
    border-radius: 5px;
    transition: border-color 0.3s;
    -moz-appearance: textfield;
  }
}
/* Media queries for responsiveness */
@media (max-width: 768px) {
  .otp {
    height: 50vh;
    width: 40vw;
  }

  .otp h3 {
    font-size: x-large;
  }

  .otp-inputs input {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  .verify-button {
    height: 15%;
    width: 50%;
    font-size: 3vh;
  }
}

@media (max-width: 480px) {
  .otp {
    height: 40vh;
    width: 80vw;
  }

  .otp h3 {
    font-size: larger;
  }

  .otp-inputs input {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .verify-button {
    height: 18%;
    width: 60%;
    font-size: 3vh;
  }
}
.resend-button {
  padding: 8px;
  border-radius: 50px;
}
