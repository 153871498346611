.mobile-verify-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
#inp {
  width: 90%;
}
.mobile-verify {
  background: white;
  border-radius: 12px;
  padding: 30px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-verify h3 {
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: 500;
  color: black;
  text-align: center;
}

.mobile-verify input {
  width: 100%;
  padding: 14px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  color: black;
}

.mobile-verify input:focus {
  border-color: #2bb673;
}

/* Button Styling */
.mobile-verify button {
  padding: 14px 24px;
  background: #2bb673;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  max-width: 220px;
  margin-top: 10px;
}

.mobile-verify button:hover {
  background: #1b8d4e;
}

.signup-prompt {
  margin-top: 20px;
  font-size: 14px;
  color: black;
  text-align: center;
}

.signup-link {
  color: #2bb673;
  text-decoration: none;
  cursor: pointer;
}

.signup-link:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .mobile-verify {
    width: 80%;
    padding: 25px;
  }

  .mobile-verify h3 {
    font-size: 22px;
  }

  .mobile-verify input {
    font-size: 14px;
    padding: 12px;
    margin-bottom: 18px;
  }

  .mobile-verify button {
    font-size: 14px;
    padding: 12px 22px;
  }

  .signup-prompt {
    font-size: 12px;
  }
}

@media only screen and (max-width: 468px) {
  .mobile-verify {
    width: 90%;
    padding: 20px;
  }

  .mobile-verify h3 {
    font-size: 20px;
  }

  .mobile-verify input {
    font-size: 12px;
    padding: 10px;
    margin-bottom: 16px;
  }

  .mobile-verify button {
    font-size: 12px;
    padding: 10px 20px;
  }

  .signup-prompt {
    font-size: 10px;
  }
}
